#IE6 {
  #dokuwiki__aside {
    width: 23%;
    margin: 0 2% 0 0;
    padding: 0;
  }
  #dokuwiki__content {
    width: 75%;
    margin: 0;
    padding: 0;
  }
  .not-header {
    margin-top: 55px;
  }
  .dokuwiki a.urlextern {
    background-image: none;
    padding-left: 0;
  }
}
