@import "variables.less";

h1, h2, h3, h4, h5, h6 {
  position: relative;

  > .anchor {
    position: absolute;
    right: 100%;
    padding-right: 2px;
    padding-top: 1px;
    height: 100%;
    display: noen;
    opacity: 0;
    font-size: 80%;
    line-height: inherit;
    transition: opacity 100ms ease-in-out;
    color: @text-color;
    z-index: 10;

    &:hover, &:focus {
      display: inline-block;
      opacity: 1;
    }
  }

  &:hover > .anchor,
  &:focus > .anchor {
    display: inline-block;
    color: #222;
    opacity: 1;
  }
}
