.toolbutton,
.button:not(.btn_secedit),
#media_manager .btn {
  .btn;
  .btn-default;
  padding:0px;
}

.toolbutton {
  .btn-sm;
}

.btn_secedit .button {
  .btn-xs;
}

.dokuwiki .secedit {
  z-index: 100; // position on top of highlight bar
  position: relative;
}

html[dir="rtl"] {
  .dokuwiki .secedit {
    float: left;
  }
}
